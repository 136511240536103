import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "transaction-flow"
    }}>{`Transaction flow`}</h1>
    <p>{`A plugin can generate transactions as a result of a pickup. These are collected by NOD and can be distributed to PTOs. NOD has no requirement for the transaction format.`}</p>
    <p>{`The transaction flow is the mechanism a Plugin has to give feedback to a PTO that a Pickup has been completed / failed. This requires the distribution of these transactions between the NOD Rear System and the PTO.
In cases where such a transaction flow is not defined, the PTO must itself call NOD Back System to find the status of an Order Group.
The TVM Scenario gives a single PTO full control of both Sales and Pickup so that all traffic from a NOD Client can go via a proprietary intermediate layer.`}</p>
    <p>{`A PTO has 3 options for following up the status of an OrderGroup:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Set up a transaction flow between NOD Plugin and own systems.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Restrict sales and pickup to always go through your own middleware.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ask NOD Rear System Status for Single Order.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      